body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  video {
    width: 100%;
    height: auto;
  }
}

#bootstrap-overrides {
  background-color: #f2f3f4;
  color: #5e6268;

  .btn-primary {
    background-color: #5e6268;
    color: #f2f3f4;
    border: 1px solid #5e6268;
  }

  form-control {
    background-color: #f2f2e8 !important;
    border-color: #5e6268 !important;
  }

  .form-select .form-control {
    background-color: #f2f2e8 !important;
    border-color: #f2f2e8 !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .form-label {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: normal;
  }

  tbody > tr > td {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif !important;
  }
  .table > :not(caption) > * > * {
    background-color: #f2f2e8 !important;
    color: #5e6268 !important;
  }

  table > thead {
    letter-spacing: 0.25em;
  }

  .bg-primary {
    background-color: #f2f3f4 !important;
  }

  .modal-content {
    background-color: #f2f3f4 !important;
    border-radius: 10px;
  }

  .modal-body {
    background-color: #f2f3f4 !important;
    border-radius: 0px 0px 10px 10px;
  }

  .modal-header {
    background-color: #f2f3f4 !important;
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
  }

  .dropdown button {
    background-color: #f2f2e8 !important;
    color: #5e6268 !important;
    border: 2px solid #5e6268;
  }

  .dropdown-menu {
    background-color: #f2f2e8 !important;
    color: #5e6268 !important;
    border: 2px solid #f2f2e8;
  }

  .form-select {
    background-color: #f2f2e8 !important;
    color: #5e6268 !important;
    border: 2px solid #f2f2e8;
  }

  .primary {
    color: #5e6268 !important;
  }

  .danger {
    background-color: #f9a9ab !important;
  }

  .page-link {
    color: #5e6268 !important;
    border: 2px solid #5e6268 !important;
    background-color: transparent !important;
  }

  .active > .page-link {
    background-color: #5e6268 !important;
    color: #f2f3f4 !important;
  }

  .pagination {
    margin-top: 10px !important;

    li {
      min-width: 40px;
    }
  }
}

@media (prefers-color-scheme: dark) {
  #bootstrap-overrides {
    background-color: #5e6268;
    color: #f2f3f4;

    .btn-primary {
      background-color: #f2f3f4;
      color: #5e6268;
      border-left: 1px solid #5e6268;
    }

    .form-control {
      background-color: #f2f2e8 !important;
      border-color: #5e6268 !important;
      border-right: none;
    }

    .form-select .form-control {
      background-color: #f2f2e8 !important;
      border-color: #f2f2e8 !important;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .form-label {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      letter-spacing: normal;
    }

    tbody > tr > td {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif !important;
    }

    .table > :not(caption) > * > * {
      background-color: #5e6268 !important;
      color: #f2f2e8 !important;
    }

    table > thead {
      letter-spacing: 0.25em;
    }

    .bg-primary {
      background-color: #5e6268 !important;
    }

    .dropdown button {
      background-color: #f2f2e8 !important;
      color: #5e6268 !important;
    }

    .dropdown-menu {
      background-color: #5e6268 !important;
      border: 2px solid #5e6268;
    }

    .form-select {
      background-color: #f2f2e8 !important;
      color: #5e6268 !important;
      border: 2px solid #f2f2e8;
    }

    .primary {
      color: #f2f2e8 !important;
    }

    .dropdown-item {
      color: #f2f2e8 !important;
      svg {
        color: #f2f2e8 !important;
      }
    }
    .dropdown-item:hover {
      color: #5e6268 !important;
      background-color: #f2f2e8 !important;
    }

    .modal-content {
      background-color: #5e6268 !important;
      border-radius: 10px;
    }

    .modal-body {
      background-color: #5e6268 !important;
      border-radius: 0px 0px 10px 10px;
    }

    .modal-header {
      background-color: #5e6268 !important;
      border-radius: 10px 10px 0px 0px;
      border-bottom: none;
    }

    .danger {
      background-color: #c94414 !important;
    }

    .page-link {
      color: #f2f3f4 !important;
      border: 2px solid #f2f3f4 !important;
      background-color: transparent !important;
    }

    .active > .page-link {
      background-color: #f2f3f4 !important;
      color: #5e6268 !important;
    }

    .pagination {
      margin-top: 10px !important;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spin {
  -webkit-animation: spinAnim 2s linear infinite;
  -moz-animation: spinAnim 2s linear infinite;
  animation: spinAnim 2s linear infinite;
}

@-moz-keyframes spinAnim {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spinAnim {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinAnim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
